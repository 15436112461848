import { useEffect, useState } from 'react';
import { Flex, Heart } from './CustomChart';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { BirthdayGreeting } from './BDay';

const password = 'Rs@46';

const encryptPassword = (password) => {
  const encryptedPassword = password
    .split('')
    .map((char) => {
      return String.fromCharCode(char.charCodeAt(0) + 499);
    })
    .join('');
  return encryptedPassword;
};

const decryptPassword = (encryptedPassword) => {
  const password = encryptedPassword
    .split('')
    .map((char) => {
      return String.fromCharCode(char.charCodeAt(0) - 499);
    })
    .join('');
  return password;
};

function App() {
  const pwd = localStorage.getItem('password');
  const [passwordInput, setPasswordInput] = useState('');
  const [isPasswordMatched, setIsPasswordMatched] = useState(false);
  const [isWrongPassword, setIsWrongPassword] = useState(false);
  const [noPassword, setNoPassword] = useState(false);

  useEffect(() => {
    if (!pwd) {
      return;
    }
    if (password === decryptPassword(pwd)) {
      setIsPasswordMatched(true);
    }
  }, [pwd]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (!passwordInput) {
        setNoPassword(true);
        return;
      }
      localStorage.setItem('password', encryptPassword(passwordInput));
      if (passwordInput === password) {
        setIsPasswordMatched(true);
      } else {
        setIsWrongPassword(true);
      }
    }
  };

  const handleClick = () => {
    if (!passwordInput) {
      setNoPassword(true);
      return;
    }
    localStorage.setItem('password', encryptPassword(passwordInput));
    if (passwordInput === password) {
      setIsPasswordMatched(true);
    } else {
      setIsWrongPassword(true);
    }
  };

  const handleChange = (e) => {
    // Check if enter key is pressed
    setPasswordInput(e.target.value);
    setIsWrongPassword(false);
    setNoPassword(false);
  };

  return (
    <AppWrapper className="app">
      <Helmet>{isPasswordMatched ? <title>I love you!</title> : <title>Enter password</title>}</Helmet>
      <Router>
        {isPasswordMatched ? (
          <Routes>
            <Route path="/" element={<Heart />} />
            <Route path="/hbd01" element={<BirthdayGreeting />} />
          </Routes>
        ) : (
          <FlexCenter>
            <h1>Enter password</h1>
            <InputWrapper>
              <Input type="password" placeholder="Enter password" onKeyDown={handleKeyDown} onChange={handleChange} />
              <InputButton onClick={handleClick}>
                <ButtonIcon />
              </InputButton>
            </InputWrapper>
            <ErrorWrapper>
              <DisappearingText show={isWrongPassword}>Wrong password</DisappearingText>
              <DisappearingText show={noPassword}>Please enter a password</DisappearingText>
            </ErrorWrapper>
          </FlexCenter>
        )}
      </Router>
    </AppWrapper>
  );
}

const AppWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f8f9fa;
`;

const FlexCenter = styled(Flex)`
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Input = styled.input`
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  display: block;
  width: 100%;
  color: var(--input-color, '#000');
  border: 0.0625rem solid #e1e1e1;
  background-color: transparent;
  font-family: 'Inter', sans-serif;
  line-height: 2rem;
  font-size: 1.5rem;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  padding: 0.5rem 1rem;
  cursor: text;
  overflow: hidden;
  background-color: #fff;

  &:focus {
    outline: none;
    border-color: #e91e63;
    box-shadow: 0 0 0 1px rgba(233, 30, 99, 0.34), 0 0 0 4px rgba(233, 30, 99, 0.16);
    transition: box-shadow, border 0.15s;
  }
`;

const InputButton = styled.button`
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  display: block;
  color: #fff;
  background-color: #e91e63;
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  border: 0;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="m3 3 3 9-3 9 19-9Z" />
    <path d="M6 12h16" />
  </svg>
);

const ErrorWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const DisappearingText = styled.p`
  color: red;
  transition: opacity 0.5s;
  opacity: ${({ show }) => (show ? 1 : 0)};
  position: absolute;
  top: 0;
  margin-bottom: 0;
`;

export default App;
