import React, { useEffect, useRef } from 'react';
import { select, selectAll } from 'd3-selection';
import { axisBottom, axisLeft, axisRight } from 'd3-axis';
import { scaleLinear } from 'd3-scale';
import { line, curveMonotoneX, area, curveLinear } from 'd3-shape';
import { easeLinear } from 'd3-ease';
import { axisTop } from 'd3-axis';
import { max } from 'd3-array';
import { format } from 'd3-format';
import 'd3-transition';
import styled, { withTheme } from 'styled-components';
import { color, space, typography, layout, position, shadow, flexbox, border } from 'styled-system';

export const Flex = styled.div`
  display: flex;
  ${color}
  ${space}
  ${typography}
  ${layout}
  ${position}
  ${shadow}
  ${flexbox}
  ${border}
`;

const d3 = {
  select,
  selectAll,
  axisBottom,
  axisRight,
  format,
  scaleLinear,
  axisLeft,
  max,
  line,
  curveMonotoneX,
  area,
  axisTop,
  easeLinear,
  curveLinear,
};

const CustomChartWrapper = styled(Flex)`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: ${(props) => props.maxHeight}px;
`;

const StyledSvg = styled.svg`
  width: ${(props) => (props.chartWidth ? `${props.chartWidth}px` : '100%')};
  height: ${(props) => (props.chartWidth ? `${props.chartWidth}px` : '100%')};
  .dashed-line {
    stroke-dasharray: 6, 6;
  }
`;

export const Heart = withTheme(
  ({ chartId = 'test', width = 900, height = 600, maxHeight = 720, radius = 5, data = [] }) => {
    const chartMaxHeight = maxHeight;
    const svgNode = useRef(null);
    const svgNodeWrapper = useRef(null);
    const margin = { top: 50, right: 50, bottom: 50, left: 50 };
    const cx = width * 0.5;
    const cy = height * 0.5;
    const pink = '#E91E63';
    const purple = '#925fff';

    useEffect(() => {
      renderChart();
      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const renderChart = () => {
      const svgObj = d3.select(svgNode.current).select(`#${chartId}-chart-wrapper`).data([data]);

      const iData = [
        { x: -150, y: 0 },
        { x: -150, y: 10 },
        { x: -150, y: 20 },
        { x: -150, y: 40 },
        { x: -150, y: 60 },
        { x: -150, y: 80 },
        { x: -130, y: 80 },
        { x: -130, y: 60 },
        { x: -130, y: 40 },
        { x: -130, y: 20 },
        { x: -130, y: 10 },
        { x: -130, y: 0 },
        { x: -130, y: -10 },
        { x: -130, y: -20 },
        { x: -130, y: -40 },
        { x: -130, y: -60 },
        { x: -150, y: -60 },
        { x: -150, y: -40 },
        { x: -150, y: -20 },
        { x: -150, y: -10 },
        { x: -150, y: 0 },
      ];

      const uData = [
        { x: 130, y: 0 },
        { x: 130, y: 10 },
        { x: 130, y: 20 },
        { x: 130, y: 40 },
        { x: 130, y: 60 },
        { x: 130, y: 80 },
        { x: 240, y: 80 },
        { x: 240, y: 60 },
        { x: 240, y: 40 },
        { x: 240, y: 20 },
        { x: 240, y: 10 },
        { x: 240, y: 0 },
        { x: 240, y: -10 },
        { x: 240, y: -20 },
        { x: 240, y: -40 },
        { x: 240, y: -60 },
        { x: 220, y: -60 },
        { x: 220, y: -40 },
        { x: 220, y: -20 },
        { x: 220, y: -10 },
        { x: 220, y: 0 },
        { x: 220, y: 0 },
        { x: 220, y: 10 },
        { x: 220, y: 20 },
        { x: 220, y: 40 },
        { x: 220, y: 60 },
        { x: 150, y: 60 },
        { x: 150, y: 40 },
        { x: 150, y: 20 },
        { x: 150, y: 10 },
        { x: 150, y: 0 },
        { x: 150, y: -10 },
        { x: 150, y: -20 },
        { x: 150, y: -40 },
        { x: 150, y: -60 },
        { x: 130, y: -60 },
        { x: 130, y: -40 },
        { x: 130, y: -20 },
        { x: 130, y: -10 },
        { x: 130, y: -0 },
      ];

      const nData = [
        {
          x: 0,
          y: 200,
        },
        {
          x: 0,
          y: 210,
        },
        {
          x: 0,
          y: 210,
        },
        {
          x: 0,
          y: 220,
        },
        {
          x: 0,
          y: 240,
        },
        {
          x: 0,
          y: 260,
        },
        {
          x: 0,
          y: 280,
        },
        {
          x: 20,
          y: 280,
        },
        {
          x: 20,
          y: 260,
        },
        {
          x: 20,
          y: 160,
        },
        {
          x: 70,
          y: 280,
        },
        {
          x: 100,
          y: 280,
        },
        {
          x: 100,
          y: 140,
        },
        {
          x: 80,
          y: 140,
        },
        {
          x: 80,
          y: 260,
        },
        {
          x: 30,
          y: 140,
        },
        {
          x: 0,
          y: 140,
        },
        {
          x: 0,
          y: 200,
        },
      ];

      const aData = [
        {
          x: -130,
          y: 200,
        },
        {
          x: -180,
          y: 280,
        },
        {
          x: -150,
          y: 280,
        },
        {
          x: -125,
          y: 240,
        },
        {
          x: -75,
          y: 240,
        },
        {
          x: -55,
          y: 280,
        },
        {
          x: -30,
          y: 280,
        },
        {
          x: -70,
          y: 200,
        },
        {
          x: -97,
          y: 140,
        },
        {
          x: -130,
          y: 200,
        },
      ];

      const aOData = [
        {
          x: -117,
          y: 220,
        },
        {
          x: -85,
          y: 220,
        },
        {
          x: -100,
          y: 185,
        },
      ];

      const a1Data = [
        {
          x: 290,
          y: 200,
        },
        {
          x: 240,
          y: 280,
        },
        {
          x: 270,
          y: 280,
        },
        {
          x: 295,
          y: 240,
        },
        {
          x: 345,
          y: 240,
        },
        {
          x: 365,
          y: 280,
        },
        {
          x: 390,
          y: 280,
        },
        {
          x: 350,
          y: 200,
        },
        {
          x: 323,
          y: 140,
        },
        {
          x: 290,
          y: 200,
        },
      ];

      const a1OData = [
        {
          x: 303,
          y: 220,
        },
        {
          x: 335,
          y: 220,
        },
        {
          x: 320,
          y: 185,
        },
      ];

      const sData = [
        {
          x: -310,
          y: 200,
        },
        {
          x: -310,
          y: 180,
        },
        {
          x: -310,
          y: 160,
        },
        {
          x: -310,
          y: 140,
        },
        {
          x: -290,
          y: 140,
        },
        {
          x: -270,
          y: 140,
        },
        {
          x: -250,
          y: 140,
        },
        {
          x: -210,
          y: 140,
        },
        {
          x: -210,
          y: 170,
        },
        {
          x: -230,
          y: 170,
        },
        {
          x: -230,
          y: 160,
        },
        {
          x: -280,
          y: 160,
        },
        {
          x: -280,
          y: 200,
        },
        {
          x: -210,
          y: 200,
        },
        {
          x: -210,
          y: 280,
        },
        {
          x: -310,
          y: 280,
        },
        {
          x: -310,
          y: 250,
        },
        {
          x: -290,
          y: 250,
        },
        {
          x: -290,
          y: 260,
        },
        {
          x: -240,
          y: 260,
        },
        {
          x: -240,
          y: 220,
        },
        {
          x: -310,
          y: 220,
        },
        {
          x: -310,
          y: 200,
        },
      ];

      const yData = [
        {
          x: 140,
          y: 140,
        },
        {
          x: 160,
          y: 140,
        },
        {
          x: 190,
          y: 200,
        },
        {
          x: 210,
          y: 140,
        },
        {
          x: 230,
          y: 140,
        },
        {
          x: 190,
          y: 280,
        },
        {
          x: 150,
          y: 280,
        },
        {
          x: 150,
          y: 260,
        },
        {
          x: 170,
          y: 260,
        },
        {
          x: 180,
          y: 220,
        },
        {
          x: 140,
          y: 140,
        },
      ];

      const pulse = [
        { x: -15 * radius, y: 0 },
        { x: -6 * radius, y: 0 },
        { x: -4 * radius, y: -4 * radius },
        { x: 0, y: 6 * radius },
        { x: 4 * radius, y: -3 * radius },
        { x: 6 * radius, y: 0 },
        { x: 15 * radius, y: 0 },
      ];

      const x = d3
        .scaleLinear()
        .domain([-380, 380])
        .range([0, width - margin.right - margin.left]);

      const y = d3
        .scaleLinear()
        .domain([-100, 300])
        .range([0, height - margin.top - margin.bottom]);

      function limitPrecision(num, precision = 2) {
        return Math.round(num * 10 ** precision) / 10 ** precision;
      }

      function rotate(point, center, radians) {
        const cos = Math.cos(radians),
          sin = Math.sin(radians),
          nx = cos * (point.x - center.x) + sin * (point.y - center.y) + center.x,
          ny = cos * (point.y - center.y) - sin * (point.x - center.x) + center.y;
        return { x: nx, y: ny };
      }

      function vec(p1, p2) {
        const dx = p2.x - p1.x;
        const dy = p2.y - p1.y;
        const mag = Math.hypot(dx, dy);
        const unit = mag !== 0 ? { x: dx / mag, y: dy / mag } : { x: 0, y: 0 };
        const normal = rotate(unit, { x: 0, y: 0 }, Math.PI / 2);
        const radians = Math.atan2(dy, dx);
        const normalized = 2 * Math.PI + (Math.round(radians) % (2 * Math.PI));
        const degrees = (180 * radians) / Math.PI;
        const degreesNormalized = (360 + Math.round(degrees)) % 360;

        return {
          dx,
          dy,
          mag,
          unit,
          normal,
          p1: { ...p1 },
          p2: { ...p2 },
          angle: {
            radians,
            normalized,
            degrees,
            degreesNormalized,
          },
        };
      }

      function roundSvgPath(pathData, radius, style) {
        if (!pathData || radius === 0) {
          return pathData;
        }

        let pathCoords = pathData
          .split(/[a-zA-Z]/)
          .reduce(function (parts, part) {
            var match = part.match(/(-?[\d]+(\.\d+)?)/g);
            if (match) {
              parts.push({
                x: +match[0],
                y: +match[1],
              });
            }
            return parts;
          }, [])
          .filter((e, i, arr) => {
            const prev = arr[i === 0 ? arr.length - 1 : i - 1];
            return e.x !== prev.x || e.y !== prev.y;
          });

        const path = [];
        for (let i = 0; i < pathCoords.length; i++) {
          const c2Index = (i + 1) % pathCoords.length;
          const c3Index = (i + 2) % pathCoords.length;

          const c1 = pathCoords[i];
          const c2 = pathCoords[c2Index];
          const c3 = pathCoords[c3Index];

          const vC1c2 = vec(c2, c1);
          const vC3c2 = vec(c2, c3);

          const angle = Math.abs(
            Math.atan2(
              vC1c2.dx * vC3c2.dy - vC1c2.dy * vC3c2.dx, // cross product
              vC1c2.dx * vC3c2.dx + vC1c2.dy * vC3c2.dy, // dot product
            ),
          );

          const cornerLength = Math.min(radius, vC1c2.mag / 2, vC3c2.mag / 2);

          const bc = cornerLength;
          const bd = Math.cos(angle / 2) * bc;
          const fd = Math.sin(angle / 2) * bd;
          const bf = Math.cos(angle / 2) * bd; // simplify from abs(cos(PI - angle / 2))
          const ce = fd / (bf / bc);
          const a = ce;

          const numberOfPointsInCircle = (2 * Math.PI) / (Math.PI - angle);
          let idealControlPointDistance;

          if (style === 'circle') {
            idealControlPointDistance = (4 / 3) * Math.tan(Math.PI / (2 * numberOfPointsInCircle)) * a;
          } else if (style === 'approx') {
            idealControlPointDistance =
              (4 / 3) *
              Math.tan(Math.PI / (2 * ((2 * Math.PI) / angle))) *
              cornerLength *
              (angle < Math.PI / 2 ? 1 + Math.cos(angle) : 2 - Math.sin(angle));
          } else if (style === 'hand') {
            idealControlPointDistance =
              (4 / 3) * Math.tan(Math.PI / (2 * ((2 * Math.PI) / angle))) * cornerLength * (2 + Math.sin(angle));
          }

          const cpDistance = cornerLength - idealControlPointDistance;

          let c1c2curvePoint = {
            x: c2.x + vC1c2.unit.x * cornerLength,
            y: c2.y + vC1c2.unit.y * cornerLength,
          };
          let c1c2curveCP = {
            x: c2.x + vC1c2.unit.x * cpDistance,
            y: c2.y + vC1c2.unit.y * cpDistance,
          };

          let c3c2curvePoint = {
            x: c2.x + vC3c2.unit.x * cornerLength,
            y: c2.y + vC3c2.unit.y * cornerLength,
          };
          let c3c2curveCP = {
            x: c2.x + vC3c2.unit.x * cpDistance,
            y: c2.y + vC3c2.unit.y * cpDistance,
          };

          const limit = (point) => ({
            x: limitPrecision(point.x, 3),
            y: limitPrecision(point.y, 3),
          });

          c1c2curvePoint = limit(c1c2curvePoint);
          c1c2curveCP = limit(c1c2curveCP);
          c3c2curvePoint = limit(c3c2curvePoint);
          c3c2curveCP = limit(c3c2curveCP);

          if (i === pathCoords.length - 1) {
            path.unshift(`M ${c3c2curvePoint.x} ${c3c2curvePoint.y}`);
          }

          path.push(`L ${c1c2curvePoint.x} ${c1c2curvePoint.y}`);

          path.push(
            `C ${c1c2curveCP.x} ${c1c2curveCP.y}, ${c3c2curveCP.x} ${c3c2curveCP.y}, ${c3c2curvePoint.x} ${c3c2curvePoint.y}`,
          );
        }

        path.push('Z');

        return path.join(' ');
      }

      const uPath = svgObj
        .append('g')
        .append('path')
        .datum(uData)
        .attr('class', 'pulse-path')
        .attr('clip-path', 'url(#upath-clip)')
        .attr('stroke', pink)
        .attr('fill', purple)
        .attr('fill-opacity', '0.1')
        .attr('stroke-width', 5)
        .attr('d', (d) =>
          roundSvgPath(
            d3
              .line()
              .x((d) => {
                return x(d.x) + margin.left;
              })
              .y((d) => {
                return y(d.y) + margin.top;
              })
              .curve(d3.curveLinear)(d),
            6,
            'approx',
          ),
        );

      const nPath = svgObj
        .append('g')
        .append('path')
        .datum(nData)
        .attr('class', 'pulse-path')
        .attr('clip-path', 'url(#npath-clip)')
        .attr('stroke', pink)
        .attr('fill', purple)
        .attr('fill-opacity', '0.1')
        .attr('stroke-width', 5)
        .attr('d', (d) =>
          roundSvgPath(
            d3
              .line()
              .x((d) => {
                return x(d.x) + margin.left;
              })
              .y((d) => {
                return y(d.y) + margin.top;
              })
              .curve(d3.curveLinear)(d),
            6,
            'approx',
          ),
        );

      const aPath = svgObj
        .append('g')
        .append('path')
        .datum(aData)
        .attr('class', 'pulse-path')
        .attr('clip-path', 'url(#apath-clip)')
        .attr('stroke', pink)
        .attr('fill', purple)
        .attr('fill-opacity', '0.1')
        .attr('stroke-width', 5)
        .attr('d', (d) =>
          roundSvgPath(
            d3
              .line()
              .x((d) => {
                return x(d.x) + margin.left;
              })
              .y((d) => {
                return y(d.y) + margin.top;
              })
              .curve(d3.curveLinear)(d),
            6,
            'approx',
          ),
        );

      const aOPath = svgObj
        .append('g')
        .append('path')
        .datum(aOData)
        .attr('class', 'pulse-path')
        .attr('clip-path', 'url(#aopath-clip)')
        .attr('stroke', pink)
        .attr('fill', 'white')
        .attr('fill-opacity', '1')
        .attr('stroke-width', 5)
        .attr('d', (d) =>
          roundSvgPath(
            d3
              .line()
              .x((d) => {
                return x(d.x) + margin.left;
              })
              .y((d) => {
                return y(d.y) + margin.top;
              })
              .curve(d3.curveLinear)(d),
            6,
            'approx',
          ),
        );

      const a1Path = svgObj
        .append('g')
        .append('path')
        .datum(a1Data)
        .attr('class', 'pulse-path')
        .attr('clip-path', 'url(#a1path-clip)')
        .attr('stroke', pink)
        .attr('fill', purple)
        .attr('fill-opacity', '0.1')
        .attr('stroke-width', 5)
        .attr('d', (d) =>
          roundSvgPath(
            d3
              .line()
              .x((d) => {
                return x(d.x) + margin.left;
              })
              .y((d) => {
                return y(d.y) + margin.top;
              })
              .curve(d3.curveLinear)(d),
            6,
            'approx',
          ),
        );

      const a1OPath = svgObj
        .append('g')
        .append('path')
        .datum(a1OData)
        .attr('class', 'pulse-path')
        .attr('clip-path', 'url(#a1opath-clip)')
        .attr('stroke', pink)
        .attr('fill', 'white')
        .attr('fill-opacity', '1')
        .attr('stroke-width', 5)
        .attr('d', (d) =>
          roundSvgPath(
            d3
              .line()
              .x((d) => {
                return x(d.x) + margin.left;
              })
              .y((d) => {
                return y(d.y) + margin.top;
              })
              .curve(d3.curveLinear)(d),
            6,
            'approx',
          ),
        );

      const iPath = svgObj
        .append('g')
        .append('path')
        .datum(iData)
        .attr('class', 'pulse-path')
        .attr('clip-path', 'url(#ipath-clip)')
        .attr('stroke', pink)
        .attr('fill', purple)
        .attr('fill-opacity', '0.1')
        .attr('stroke-width', 5)
        .attr('d', (d) =>
          roundSvgPath(
            d3
              .line()
              .x((d) => {
                return x(d.x) + margin.left;
              })
              .y((d) => {
                return y(d.y) + margin.top;
              })
              .curve(d3.curveLinear)(d),
            6,
            'approx',
          ),
        );

      const sPath = svgObj
        .append('g')
        .append('path')
        .datum(sData)
        .attr('class', 'pulse-path')
        .attr('clip-path', 'url(#spath-clip)')
        .attr('stroke', pink)
        .attr('fill', purple)
        .attr('fill-opacity', '0.1')
        .attr('stroke-width', 5)
        .attr('d', (d) =>
          roundSvgPath(
            d3
              .line()
              .x((d) => {
                return x(d.x) + margin.left;
              })
              .y((d) => {
                return y(d.y) + margin.top;
              })
              .curve(d3.curveLinear)(d),
            6,
            'approx',
          ),
        );

      const yPath = svgObj
        .append('g')
        .append('path')
        .datum(yData)
        .attr('class', 'pulse-path')
        .attr('clip-path', 'url(#ypath-clip)')
        .attr('stroke', pink)
        .attr('fill', purple)
        .attr('fill-opacity', '0.1')
        .attr('stroke-width', 5)
        .attr('d', (d) =>
          roundSvgPath(
            d3
              .line()
              .x((d) => {
                return x(d.x) + margin.left;
              })
              .y((d) => {
                return y(d.y) + margin.top;
              })
              .curve(d3.curveLinear)(d),
            6,
            'approx',
          ),
        );

      const pulsePath = svgObj
        .append('g')
        .append('path')
        .datum(pulse)
        .attr('class', 'pulse-path')
        .attr('clip-path', 'url(#pulse-clip)')
        .attr('stroke', pink)
        .attr('fill-opacity', '0')
        .attr('stroke-width', 5)
        .attr(
          'd',
          d3
            .line()
            .x((d) => {
              return x(d.x) + margin.left;
            })
            .y((d) => {
              return y(d.y) + margin.top;
            })
            .curve(d3.curveLinear),
        );

      const animatePath = (path, callback) => {
        const totalLength = path.node().getTotalLength();
        path
          .attr('stroke-dasharray', totalLength + ' ' + totalLength)
          .attr('stroke-dashoffset', totalLength)
          .transition()
          .duration(1000)
          .ease(d3.easeLinear)
          .attr('stroke-dashoffset', 0)
          .on('end', () => {
            callback();
          });
      };

      const getHeartPoints = () => {
        let points = [];
        let theta = 0;
        let sides = 360;
        let cx = 0;
        let cy = 0;

        while (theta < Math.PI * 2) {
          let x = 16 * Math.pow(Math.sin(theta), 3);
          let y = 13 * Math.cos(theta) - 5 * Math.cos(2 * theta) - 2 * Math.cos(3 * theta) - Math.cos(4 * theta);
          x *= radius;
          y *= radius;

          points.push({ x: cx - x, y: cy - y });
          theta += (Math.PI * 2) / sides;
        }
        return points;
      };

      const heartPath = svgObj
        .append('g')
        .append('path')
        .datum(getHeartPoints())
        .attr('class', 'heart-path')
        .attr('clip-path', 'url(#clip)')
        .attr('stroke', pink)
        .attr('fill', purple)
        .attr('fill-opacity', '0.1')
        .attr('stroke-width', 5)
        .attr(
          'd',
          d3
            .line()
            .x((d) => {
              return x(d.x) + margin.left;
            })
            .y((d) => {
              return y(d.y) + margin.top;
            })
            .curve(d3.curveLinear),
        );

      const xAxis = d3.axisTop().scale(x).ticks(10);

      const yAxis = d3.axisLeft().scale(y).ticks(10);

      svgObj
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(${margin.left},${cy})`)
        .call(xAxis)
        .selectAll('text')
        .attr('y', 0)
        .attr('x', 9)
        .attr('dy', '.35em')
        .attr('transform', 'rotate(90)')
        .style('text-anchor', 'start');

      svgObj.append('g').attr('class', 'y-axis').attr('transform', `translate(${cx},${margin.top})`).call(yAxis);

      const animateHeart = () =>
        animatePath(iPath, () => {
          animatePath(heartPath, () => {
            animatePath(pulsePath, () => {
              animatePath(uPath, () => {
                animatePath(sPath, () => {
                  animatePath(aPath, () => {
                    animatePath(aOPath, () => {
                      animatePath(nPath, () => {
                        animatePath(yPath, () => {
                          animatePath(a1Path, () => {
                            animatePath(a1OPath, () => {
                              animatePath(iPath, animateHeart);
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });

      animateHeart();
    };

    return (
      <CustomChartWrapper
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        ref={svgNodeWrapper}
        maxHeight={chartMaxHeight}
      >
        <StyledSvg
          ref={svgNode}
          chartWidth={width + 20}
          chartHeight={height + 20}
          viewBox={`0 0 ${width + 20} ${height + 20}`}
        >
          <g
            id={`${chartId}-chart-wrapper`}
            transform={`translate(${radius + 10},${radius + 10})`}
            className="chart-wrapper"
          />
        </StyledSvg>
      </CustomChartWrapper>
    );
  },
);
